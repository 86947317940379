import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { getPayrollWeekInfo, getWeekOptions } from '../utils/payrollDates';

function TimeSheet() {
  const [selectedWeek, setSelectedWeek] = useState(getPayrollWeekInfo());
  const [weekOptions] = useState(getWeekOptions());
  const [timesheet, setTimesheet] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'ticketDate',
    direction: 'desc'
  });
  const navigate = useNavigate();
  
  useEffect(() => {
    let isMounted = true;  // Add mounted flag

    const fetchData = async () => {
      const resourceId = localStorage.getItem('resourceId');
      
      console.log('TimeSheet: Retrieved from localStorage:', { resourceId });
      console.log('Selected Week Filter Dates:', {
        start: new Date(selectedWeek?.filterDates?.start).toLocaleString(),
        end: new Date(selectedWeek?.filterDates?.end).toLocaleString(),
        rawStart: selectedWeek?.filterDates?.start,
        rawEnd: selectedWeek?.filterDates?.end,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });

      if (!resourceId || resourceId === 'undefined') {
        console.error('No valid resourceId found');
        if (isMounted) {
          setError('Session expired. Please login again.');
          setTimeout(() => navigate('/login'), 100);
        }
        return;
      }

      if (!selectedWeek?.filterDates) {
        if (isMounted) {
          setError('Invalid date range');
          setLoading(false);
        }
        return;
      }

      try {
        if (isMounted) setLoading(true);
        console.log('TimeSheet: Making request with:', {
          resourceId,
          weekDates: selectedWeek.filterDates
        });

        const timesheetData = await api.getDynamicsTimesheet({
          resourceId,
          weekDates: selectedWeek.filterDates
        });
        
        console.log('Raw timesheet data:', timesheetData);
        
        if (timesheetData.success && isMounted) {
          // Ensure we're working with a fresh array
          const validEntries = [...timesheetData.entries]
            .filter(entry => {
              if (entry.duration <= 0) return false;
              
              const entryDate = entry.ticketDate.split('T')[0];
              const weekStart = selectedWeek.filterDates.start;
              const weekEnd = selectedWeek.filterDates.end;

              return entryDate >= weekStart && entryDate < weekEnd;
            });

          console.log('Date filtering:', {
            weekStart: selectedWeek.filterDates.start,
            weekEnd: selectedWeek.filterDates.end,
            entries: validEntries.map(e => ({
              date: e.ticketDate,
              workOrder: e.workOrderNumber
            }))
          });

          // Create new timesheet object
          setTimesheet({
            entries: validEntries,
            totalHours: validEntries.reduce((sum, entry) => sum + entry.duration, 0)
          });

          console.log('Timesheet Entry Debug:', {
            entries: timesheetData.entries.map(entry => ({
              workOrder: entry.workOrderNumber,
              date: entry.ticketDate,
              parsedDate: new Date(entry.ticketDate).toLocaleString(),
              utcDate: new Date(entry.ticketDate).toISOString()
            }))
          });
        }
      } catch (err) {
        console.error('Error fetching timesheet:', err);
        if (err.response?.status === 401) {
          navigate('/login');
          return;
        }
        if (isMounted) {
          setError(err.message || 'Failed to load timesheet data');
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [selectedWeek, navigate]);

  const handleLogout = () => {
    console.log('Clearing localStorage on logout');
    localStorage.removeItem('userId');
    localStorage.removeItem('resourceId');
    navigate('/login');
  };

  const sortData = (entries, key, direction) => {
    return [...entries].sort((a, b) => {
      if (key.includes('Date') || key === 'createdOn') {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const getSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    if (!isActive) {
      return <span className="sort-icon inactive">↕</span>;
    }
    return (
      <span className="sort-icon active">
        {sortConfig.direction === 'asc' ? '↑' : '↓'}
      </span>
    );
  };

  const handleWeekChange = async (weekInfo) => {
    // Clear the timesheet before setting new week
    setTimesheet(null);
    setSelectedWeek(weekInfo);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      // Ensure we're parsing the date in UTC
      const date = new Date(dateString + 'T00:00:00Z');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = String(date.getUTCFullYear()).slice(2);
      return `${month}/${day}/${year}`;
    } catch (e) {
      console.error('Date formatting error:', e);
      return dateString;
    }
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    try {
      // Parse the UTC date string
      const date = new Date(dateTimeString);
      
      // Format the date part using the date's UTC components
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = String(date.getUTCFullYear()).slice(2);
      const datePart = `${month}/${day}/${year}`;

      // Get hours and minutes in Central Time (UTC-6)
      let hours = date.getUTCHours();
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      
      // Convert to 12-hour format
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert 0 to 12

      // Format time part
      const timePart = `${hours}:${minutes} ${period}`;

      return `${datePart} ${timePart}`;
    } catch (e) {
      console.error('DateTime formatting error:', e, dateTimeString);
      return dateTimeString;
    }
  };

  return (
    <div className="timesheet-container">
      <div className="header">
        <div className="header-left">
          <div className="logo-section">
            <img src="/sislogo.jpg" alt="SIS Logo" className="timesheet-logo" />
            <h2>Timesheet</h2>
          </div>
          <div className="week-selector">
            <label htmlFor="weekEnding">Week Ending:</label>
            <select 
              id="weekEnding"
              disabled={loading}
              value={selectedWeek.displayText}
              onChange={(e) => {
                const week = weekOptions.find(w => w.displayText === e.target.value);
                handleWeekChange(week);
              }}
            >
              {weekOptions.map(week => (
                <option key={week.displayText} value={week.displayText}>
                  {week.displayText}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button onClick={handleLogout}>Logout</button>
      </div>

      {loading && <div className="loading-overlay">Loading...</div>}
      {error && <div className="error">{error}</div>}

      {timesheet?.totalHours > 0 && (
        <div className="total-hours">
          <div>Total Hours: {timesheet.totalHours}</div>
          <div className="hours-note">(Does not include lunch deduction)</div>
        </div>
      )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('workOrderNumber')}>Work Order</th>
              <th onClick={() => handleSort('ticketDate')}>Ticket Date</th>
              <th onClick={() => handleSort('serviceAccount')}>Service Account</th>
              <th onClick={() => handleSort('departSIS')}>Depart SIS</th>
              <th onClick={() => handleSort('arrivePlant')}>Arrive Plant</th>
              <th onClick={() => handleSort('departPlant')}>Depart Plant</th>
              <th onClick={() => handleSort('arriveSIS')}>Arrive SIS</th>
              <th onClick={() => handleSort('duration')}>Duration</th>
            </tr>
          </thead>
          <tbody>
            {timesheet?.entries?.length > 0 ? (
              sortData(timesheet.entries, sortConfig.key, sortConfig.direction)
                .map((entry, index) => (
                  <tr key={entry.workOrderId || index}>
                    <td>{entry.workOrderNumber}</td>
                    <td>{formatDate(entry.ticketDate)}</td>
                    <td>{entry.serviceAccount}</td>
                    <td>{formatDateTime(entry.departSIS)}</td>
                    <td>{formatDateTime(entry.arrivePlant)}</td>
                    <td>{formatDateTime(entry.departPlant)}</td>
                    <td>{formatDateTime(entry.arriveSIS)}</td>
                    <td>{entry.duration}</td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="9" className="no-data">
                  {loading ? 'Loading...' : error ? error : 'No timesheet entries for selected week'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TimeSheet; 